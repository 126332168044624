import normalize from "json-api-normalizer";

import { PERM, TEMP } from "../../selectors/jobcastSelectors";
import { GET, POST, PATCH } from "../../util/apiHelpers";
import { mapKeysToCamelCase } from "../../util/formatHelpers";

import {
  receiveJobcasts,
  mergeJobCasts,
  receiveJobCast,
  setIndexTypeLoaded,
} from "../shared/jobcastActions";

import { receivePositionHistoryLoaded } from "../shared/jobcastUpdatesActions";

import { receiveJobCastDraft } from "./draftActions";

// callback action types
export const UPDATE_JOBCAST_STAGE_COUNTS = "UPDATE_JOBCAST_STAGE_COUNTS";
export const LOCK_JOBCAST = "LOCK_JOBCAST";
export const UNLOCK_JOBCAST = "UNLOCK_JOBCAST";
export const AGENCY_INCENTIVE_ADDED = "AGENCY_INCENTIVE_ADDED";
export const AGENCY_INCENTIVE_UPDATED = "AGENCY_INCENTIVE_UPDATED";
export const AGENCY_INCENTIVE_REMOVED = "AGENCY_INCENTIVE_REMOVED";

// callback actions
export const lockJobCast = (jobcast) => ({
  type: LOCK_JOBCAST,
  payload: jobcast,
});

export const unlockJobCast = (jobcast) => ({
  type: UNLOCK_JOBCAST,
  payload: jobcast,
});

export const updateJobCastStageCounts = (id, stageCounts) => ({
  type: UPDATE_JOBCAST_STAGE_COUNTS,
  payload: { id, stageCounts },
});

export const agencyIncentiveJobCast = (payload) => ({
  type: AGENCY_INCENTIVE_ADDED,
  payload,
});

export const agencyIncentiveUpdate = (payload) => ({
  type: AGENCY_INCENTIVE_UPDATED,
  payload
});

export const agencyIncentiveRemoved = (payload) => ({
  type: AGENCY_INCENTIVE_REMOVED,
  payload,
});

// api actions
export const createJobCast = (data) => (dispatch) => POST("/api/v3/employer/jobcasts", data)
  .then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveJobCast(normalized.jobcast[res.data.data.id]));
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const updateJobCast = (id, data) => (dispatch) => PATCH(`/api/v3/employer/jobcasts/${id}`, data).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(receiveJobCast(normalized.jobcast[res.data.data.id]));
});

export const fetchJobCasts =
  (filter = "all", scopedIndividual = true, temp = false) => (dispatch) => GET(
    `/api/v3/employer/jobcasts?filter=${filter}&scoped_individual=${scopedIndividual}&temp=${temp}`
  ).then((res) => {
    const normalized = normalize(res.data);
    dispatch(
      filter === "all"
        ? receiveJobcasts(normalized.jobcast)
        : mergeJobCasts(normalized.jobcast, {
          loadScope: { [temp ? TEMP : PERM]: { [filter]: true } },
        })
    );
  });

export const fetchIndexJobCasts = (filters) => (dispatch) => {
  const params = new URLSearchParams({});

  const individualString = filters.individuals;
  const organizationProfilesString = filters.organization_profiles;

  if (filters.individuals.length > 0) {
    params.append("individuals", individualString);
  }

  if (filters.organization_profiles.length > 0) {
    params.append("organization_profiles", organizationProfilesString);
  }

  if (filters.page) {
    params.append("page", filters.page);
  }

  if (filters.perPage) {
    params.append("per_page", filters.perPage);
  }

  if (filters.status) {
    params.append("status", filters.status);
  }

  if (filters.offset) {
    params.append("offset", filters.offset);
  }

  if (filters.temp) {
    params.append("temp", filters.temp);
  }

  const baseUrl = "/api/v3/employer/jobcasts/filtered_index";
  const paramsString = params.toString();

  dispatch(
    setIndexTypeLoaded({ [filters.temp ? TEMP : PERM]: { active: false } })
  );

  return GET(baseUrl + (paramsString ? `?${paramsString}` : "")).then((res) => {
    const normalized = normalize(res.data);
    const metaData = mapKeysToCamelCase(res.data.meta);
    dispatch(
      mergeJobCasts(normalized.jobcast, {
        loadScope: {
          [filters.temp ? TEMP : PERM]: { active: true },
        },
        metaData,
      })
    );

    return {
      items: normalized.jobcast,
      metaData,
    };
  });
};

export const fetchJobCast = (id) => (dispatch) => GET(`/api/v3/employer/jobcasts/${id}`).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(receiveJobCast(normalized.jobcast[res.data.data.id]));
});

export const duplicateJobCast = (id) => (dispatch) => POST(`/api/v3/employer/jobcasts/${id}/duplicate`)
  .then((res) => {
    const normalized = normalize(res.data);
    dispatch(receiveJobCastDraft(normalized.draft));
    return Promise.resolve(res.data.data);
  })
  .catch((res) => Promise.reject(res));

export const addJobCastAgencies = (data) => (dispatch) => POST(`/api/v3/employer/jobcasts/${data.jobcast_id}/add_agencies`, data)
  .then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveJobCast(normalized.jobcast[res.data.data.id]));
  })
  .catch((res) => Promise.reject(res));

export const changeJobCastOwner = (data) => (dispatch) => PATCH(`/api/v3/employer/jobcasts/${data.jobcast_id}/change_owner`, data)
  .then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveJobCast(normalized.jobcast[res.data.data.id]));
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const reopenJobcast = (jobcastId) => (dispatch) => PATCH(`/api/v3/employer/jobcasts/${jobcastId}/reopen`, {})
  .then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveJobCast(normalized.jobcast[res.data.data.id]));
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const closeJobcast = (data) => (dispatch) => PATCH(`/api/v3/employer/jobcasts/${data.jobcastId}/archive`, data)
  .then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveJobCast(normalized.jobcast[res.data.data.id]));
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const extendJobcast = (jobcastId) => (dispatch) => PATCH(`/api/v3/employer/jobcasts/${jobcastId}/extend`)
  .then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveJobCast(normalized.jobcast[res.data.data.id]));
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const unlockJobcast = (jobcastId) => (dispatch) => PATCH(`/api/v3/employer/jobcasts/${jobcastId}/unyield`)
  .then((res) => {
    dispatch(receivePositionHistoryLoaded(jobcastId));
    return dispatch(unlockJobCast(res));
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const lockJobcast = (data) => (dispatch) => PATCH(`/api/v3/employer/jobcasts/${data.jobcastId}/lock`, data)
  .then((res) => {
    dispatch(receivePositionHistoryLoaded(data.jobcastId));
    return dispatch(lockJobCast(res));
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const addAgencyIncentive = (jobcast_id, data) => (dispatch) => POST(`/api/v3/employer/agency_incentives`, { jobcast_id, ...data })
  .then((res) => {
    dispatch(receivePositionHistoryLoaded(data.jobcastId));
    return dispatch(agencyIncentiveJobCast(res));
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const updateAgencyIncentive = (jobcast_id, data) => (dispatch) => (
  PATCH(`/api/v3/employer/agency_incentives`, { jobcast_id, ...data })
    .then((res) => {
      dispatch(receivePositionHistoryLoaded(data.jobcastId));
      return dispatch(agencyIncentiveUpdate(res));
    })
    .catch((errors) => Promise.reject(errors.response.data))
);

export const removeAgencyIncentive = (jobcast_id) => (dispatch) => (
  PATCH(`/api/v3/employer/agency_incentives/remove`, { jobcast_id })
    .then((res) => {
      dispatch(receivePositionHistoryLoaded(jobcast_id));
      return dispatch(agencyIncentiveRemoved(res));
    })
    .catch((errors) => Promise.reject(errors.response.data))
);

export const remindInvoiceMemo = (id) => () => (
  PATCH(`/api/v3/employer/jobcasts/${id}/remind_invoice_memo`)
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error))
);
