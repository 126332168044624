/* eslint-disable default-param-last */
/* eslint-disable import/prefer-default-export */
import normalize from "json-api-normalizer";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import values from "lodash/values";

import {
  LOCK_JOBCAST,
  UNLOCK_JOBCAST,
  UPDATE_JOBCAST_STAGE_COUNTS,
  AGENCY_INCENTIVE_ADDED,
  AGENCY_INCENTIVE_REMOVED,
  AGENCY_INCENTIVE_UPDATED,
} from "../actions/employers/jobcastActions";

import {
  RECEIVE_JOBCASTS,
  MERGE_JOBCASTS,
  SET_INDEX_TYPE_LOADED,
  RECEIVE_JOBCAST,
  CLEAR_JOBCASTS,
} from "../actions/shared/jobcastActions";
import { PERM, TEMP } from "../selectors/jobcastSelectors";
import { ARCHIVED, UNARCHIVED } from "../selectors/recruiter/jobcastSelectors";

const defaultState = {
  loaded: false,
  items: {},
  metaData: {},
  indexTypesLoaded: {
    [PERM]: {
      [ARCHIVED]: false,
      [UNARCHIVED]: false,
    },
    [TEMP]: {
      [ARCHIVED]: false,
      [UNARCHIVED]: false,
    },
  },
};

export default function jobcastReducer(
  state = defaultState,
  { type, payload } = {}
) {
  Object.freeze(state);
  let newItems, parsedData, position, stageCounts;

  switch (type) {
    case RECEIVE_JOBCASTS:
      newItems = merge({}, state.items, payload);
      return { ...state, loaded: true, items: newItems };
    case UNLOCK_JOBCAST:
    case LOCK_JOBCAST:
    case AGENCY_INCENTIVE_ADDED:
    case AGENCY_INCENTIVE_UPDATED:
    case AGENCY_INCENTIVE_REMOVED: {
      newItems = cloneDeep(state.items);

      parsedData = normalize(JSON.parse(payload.data.jobcast));
      [position] = values(parsedData.jobcast);

      newItems[position.id] = merge({}, position, {
        loaded: true,
      });

      return { ...state, items: newItems };
    }
    case RECEIVE_JOBCAST:
      newItems = cloneDeep(state.items);
      newItems[payload.id] = merge({}, payload, { loaded: true });
      return { ...state, items: newItems };
    case MERGE_JOBCASTS:
      return merge({}, state, {
        indexTypesLoaded: payload.loadScope ? payload.loadScope : {},
        items: payload.jobcasts,
        metaData: payload.metaData,
      });
    case UPDATE_JOBCAST_STAGE_COUNTS:
      newItems = cloneDeep(state.items);
      stageCounts = newItems[payload.id].attributes?.stageCounts;

      newItems[payload.id].attributes.stageCounts = merge(
        {},
        stageCounts,
        payload.stageCounts
      );
      return { ...state, items: newItems };
    case SET_INDEX_TYPE_LOADED:
      return merge({}, state, {
        indexTypesLoaded: payload,
      });
    case CLEAR_JOBCASTS:
      return defaultState;
    default:
      return state;
  }
}
