import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import Button from '../../../../../forms/custom/Button';
import ActionModalContainer from '../../../../../General/ActionModal/ActionModalContainer';
import ActionModalContent from '../../../../../General/ActionModal/ActionModalContent';
import ActionModalFooter from '../../../../../General/ActionModal/ActionModalFooter';
import ActionModalHeader from '../../../../../General/ActionModal/ActionModalHeader';
import CommunityCoinIcon from '../../../../../shared/Icons/CommunityCoinIcon';

const useStyles = makeStyles({
  fontSize: {
    fontSize: 14,
  },
  balanceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    width: 18,
    display: 'flex',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
});

function CommunityCoinBalanceModal({ communityCoinBalance }) {
  const classes = useStyles();

  return (
    <ActionModalContainer color="blue">
      <ActionModalHeader title="Community Coins Alert" />
      <ActionModalContent>
        <div className={classes.headerRow}>
          <h5 className="semi-bold">Monitor Your Community Coins</h5>
          <br />
          <div style={{ paddingRight: 30 }}>
            <div className="subtitle-2">Current Balance:</div>
            <a href="/app/community_coins/transactions">
              <div className={classes.balanceContainer}>
                <div className="subtitle-2" style={{ color: '#49A4BD' }}>
                  {communityCoinBalance}
                </div>
                <div className={classes.iconContainer}>
                  <CommunityCoinIcon />
                </div>
              </div>
            </a>
          </div>
        </div>
        <br />
        <div className={classes.fontSize}>
          We noticed your Community Coin balance is running low. As a reminder,
          if you run out of coins you won&#39;t be able to take essential actions
          like submitting candidates to community jobs.
        </div>
        <br />
        <div className={classes.fontSize}>
          We recommend using your remaining coins on candidate submissions rather
          than “Working On” new JobCasts. The only way to earn more coins is to submit
          &quot;quality&quot; candidates.
        </div>
        <div className={classes.buttonContainer}>
          <Button
            color="blue"
            variant="primary"
            href="https://help.recruitifi.com/en/articles/9286775-what-are-community-coins-and-how-do-they-work"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </Button>
        </div>
      </ActionModalContent>
      <ActionModalFooter emptyActions />
    </ActionModalContainer>
  );
}

export default CommunityCoinBalanceModal;
