/* Global consts for each flag */
export const EMPLOYER_OMNISEARCH_FF = "employer_omnisearch";

/* Flag specific helper functios */

/**
 * Selector for feature flag filtering
 */
export const getFeatureFlag = (flagName) => (state) =>
  state.featureFlags.flags.includes(flagName);
