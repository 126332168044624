import each from "lodash/each";
import every from "lodash/every";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import { createSelector } from "reselect";

import createDeepEqualSelector from "./createDeepEqualSelector";
import { getOrganizationProfileFilterId } from "./organizationProfileSelectors";

// position types
export const TEMP = "TempPosition";
export const PERM = "PermPosition";

export const getAllJobcasts = (state) => state.jobcasts.items;

const determineStatusFilterFunc = (status) => {
  switch (status) {
    case "LIVE":
      return ({ attributes }) => attributes.status === "submitted" || attributes.status === "started";
    case "STARTED":
      return ({ attributes }) => attributes.status === "started";
    case "OPEN":
      return ({ attributes }) => attributes.status === "started" &&
        attributes.yieldedAt === null &&
        attributes.autoYieldedAt === null &&
        !attributes.approachingArchived &&
        !attributes.currentAgencyIncentive;
    case "STALE":
      return ({ attributes }) => attributes.status === "started" &&
        (attributes.autoYieldedAt !== null || attributes.approachingArchived);
    case "LOCKED":
      return ({ attributes }) => attributes.status === "started" &&
        attributes.yieldedAt !== null &&
        attributes.autoYieldedAt === null &&
        !attributes.approachingArchived;
    case "SUBMITTED":
      return ({ attributes }) => attributes.status === "submitted";
    case "CLOSED":
      return ({ attributes }) => attributes.status === "archived";
    case "AGENCY_INCENTIVE":
      return ({ attributes }) => attributes.status === "started" &&
        attributes.currentAgencyIncentive !== null &&
        attributes.yieldedAt === null &&
        attributes.autoYieldedAt === null &&
        !attributes.approachingArchived;
    default:
      return () => true;
  }
};

export const makeGetJobCasts = (switchFilter = "OPEN") => createSelector(
  [
    getAllJobcasts,
    getOrganizationProfileFilterId,
  ],
  (jobcasts, organizationProfileFilterId) => {
    const filterByStatus = () => filter(
      jobcasts,
      determineStatusFilterFunc(switchFilter)
    );

    const filterByOrganizationProfileId = (jobcasts) => filter(
      jobcasts,
      (jobcast) => jobcast.attributes.organizationProfileId ===
            organizationProfileFilterId
    );

    return organizationProfileFilterId
      ? filterByOrganizationProfileId(filterByStatus())
      : filterByStatus();
  }
);

export const getJobCasts = (state, filter) => makeGetJobCasts(filter)(state);

export const getFilterTeammateIds = (state) => state.filters.teammates;

export const getFilterBrandIds = (state) => state.filters.brands;

export const getJobCast = (state, id) => {
  const items = getAllJobcasts(state);

  if (items) {
    return items[id];
  }
  return null;
};

const buildFilterFunctions = ({
  viewingAs,
  currentIndividualId,
  status,
  isTemp = false,
  individualIds,
  organizationProfileIds,
}) => {
  const filterFunctions = [];

  if (viewingAs && viewingAs.key !== "me") {
    filterFunctions.push(
      (jobcast) => jobcast.attributes.individualId === viewingAs.id
    );
  } else if (currentIndividualId) {
    filterFunctions.push(
      (jobcast) => jobcast.attributes.individualId === currentIndividualId
    );
  }

  if (status) {
    filterFunctions.push(determineStatusFilterFunc(status));
  }

  filterFunctions.push(({ attributes }) => attributes.isTemp === isTemp);

  if (individualIds.length > 0 && individualIds[0] !== "all") {
    filterFunctions.push((jobcast) => includes(individualIds, jobcast.attributes.individualId));
  }

  if (
    organizationProfileIds.length > 0 &&
    organizationProfileIds[0] !== "all"
  ) {
    filterFunctions.push((jobcast) => includes(organizationProfileIds, jobcast.attributes.organizationProfileId));
  }

  return filterFunctions;
};

export const makeGetFilteredJobcasts = () => {
  const getJobCastFilters = (_state, ...filters) => filters;

  const getFiltersSelector = createDeepEqualSelector(
    [getJobCastFilters],
    (filters) => filters
  );

  return createSelector(
    [getJobCasts, getFilterTeammateIds, getFilterBrandIds, getFiltersSelector],
    (
      jobcasts,
      individualIds,
      organizationProfileIds,
      jobcastFilters = [null, () => true]
    ) => {
      const filterFunctions = buildFilterFunctions({
        individualIds,
        organizationProfileIds,
        ...jobcastFilters[1],
      });

      return filter(jobcasts, (jobcast) => every(filterFunctions, (filterFunction) => filterFunction(jobcast)));
    }
  );
};

export const makeGetJobCast = () => createSelector([getJobCast], (jobcast) => jobcast);

export const selectAttributes = (jobcasts, attributes) => {
  if (Array.isArray(jobcasts)) {
    return map(jobcasts, (jobcast) => {
      const selected = {};
      each(attributes, (attribute) => {
        selected[attribute] = jobcast.attributes[attribute];
      });
      return selected;
    });
  }
  if (typeof jobcasts === "object" || jobcasts instanceof Object) {
    const selected = {};
    each(attributes, (attribute) => {
      selected[attribute] = jobcasts.attributes[attribute];
    });
    return selected;
  }
  return {};
};
