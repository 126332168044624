import { GET } from "../../util/apiHelpers";

export const RECEIVE_JOBCASTS = "RECEIVE_JOBCASTS";
export const MERGE_JOBCASTS = "MERGE_JOBCASTS";
export const RECEIVE_JOBCAST = "RECEIVE_JOBCAST";
export const CLEAR_JOBCASTS = "CLEAR_JOBCASTS";
export const SET_INDEX_TYPE_LOADED = "SET_INDEX_TYPE_LOADED";

export const receiveJobcasts = (jobcasts) => ({
  type: RECEIVE_JOBCASTS,
  payload: jobcasts,
});

export const mergeJobCasts = (jobcasts, options = {}) => ({
  type: MERGE_JOBCASTS,
  payload: {
    jobcasts,
    loadScope: options.loadScope,
    metaData: options.metaData,
  },
});

export const receiveJobCast = (jobcast) => ({
  type: RECEIVE_JOBCAST,
  payload: jobcast,
});

export const clearJobcasts = () => ({
  type: CLEAR_JOBCASTS,
});

export const setIndexTypeLoaded = (indexType) => ({
  type: SET_INDEX_TYPE_LOADED,
  payload: indexType,
});

export const fetchJobcastEmployerRatings = (jobcastId, type) => GET(`/api/v3/positions/${jobcastId}/employer_ratings?type=${type}`);

export const fetchJobcastEmployerStats = (jobcastId) =>
  GET(`/api/v3/positions/${jobcastId}/employer_stats`);
