/* eslint-disable react/sort-comp */
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";

import { makeGetNotificationItems } from "../../../../selectors/notificationsSelectors";

import { capitalCase } from "../../../../util/formatHelpers";
import {
  POSITION_ARCHIVED, POSITION_ARCHIVING_SOON,
  AGENCY_INCENTIVE_ADDED, AGENCY_INCENTIVE_REMOVED, AGENCY_INCENTIVE_UPDATED
} from "../constants";

const styles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: 60,
    borderBottom: "1px solid #B0BEC5",
    padding: 12,
    cursor: "pointer",
    color: "#90A4AE",
    justifyContent: "flex-start",
    "&:hover": {
      backgroundColor: "#008dae",
      fontWeight: 600,
      "& div": {
        color: "white",
        fontWeight: 600,
      },
    },
  },
  read: {
    backgroundColor: "white",
  },
  unread: {
    backgroundColor: "rgba(208, 234, 240, 0.3)",
  },
  selected: {
    backgroundColor: "#008dae",
    fontWeight: 600,
    "& div": {
      color: "white",
      fontWeight: 600,
    },
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 6,
  },
  messageContainer: {
    width: "90%",
    display: "flex",
    color: "#37474F",
    marginLeft: 20,
  },
  subject: {
    marginBottom: "6px",
    minHeight: 8,
  },
  truncatedMessage: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "calc(100% - 10px)",
    overflow: "hidden",
    display: "inline-block",
  },
  black: {
    color: "#37474F",
  },
});

class NotificationItem extends Component {
  renderTitle() {
    const { type } = this.props.notification.attributes;

    const determineTitle = () => {
      switch (type) {
        case "Chat":
          return "chat";
        case "Question":
          return "jobcast question";
        case "Feedback":
          return "jobcast feedback";
        case "Withdrawl":
          return "withdrawn candidate";
        case "PENDING-INVITATION-ACCEPTED":
          return "Invitation Accepted";
        case POSITION_ARCHIVING_SOON:
          return "jobcast closing soon";
        case POSITION_ARCHIVED:
          return "jobcast closed";
        case AGENCY_INCENTIVE_ADDED:
        case AGENCY_INCENTIVE_REMOVED:
        case AGENCY_INCENTIVE_UPDATED:
          return type.replaceAll("-", " ").replace(/bonus/i, "").toLowerCase();
        default:
          return type?.replaceAll("-", " ");
      }
    };

    return (
      <div className={classnames("overline", "gray")}>{determineTitle()}</div>
    );
  }

  renderTimestamp() {
    const { createdAt } = this.props.notification.attributes;
    return <div>{moment(createdAt).format("MMM Do, YYYY")}</div>;
  }

  renderHeader() {
    const { classes } = this.props;
    return (
      <div className={classnames(classes.headerContainer, "overline", "gray")}>
        {this.renderTitle()}
        {this.renderTimestamp()}
      </div>
    );
  }

  renderSubject() {
    const { notification, classes } = this.props;
    const { mainSubject } = notification.attributes;

    return (
      <div className={classnames("small", classes.black, classes.subject)}>
        {mainSubject}
      </div>
    );
  }

  renderMessage() {
    const { notification, classes } = this.props;
    const { messageBody, type, notificationSpecifics } =
      notification.attributes;

    return (
      <div
        data-cy="notification-message"
        className={classnames("caption", classes.messageContainer)}
      >
        "
        <div className={classes.truncatedMessage}>
          {type === "Candidate Update"
            ? capitalCase(notificationSpecifics.event)
            : messageBody}
        </div>
        "
      </div>
    );
  }

  handleClick = () => {
    const { notification, setSelectedNotification, isSelected } = this.props;

    if (!isSelected) {
      setSelectedNotification(notification);
    }
  };

  determineStatusClass() {
    const { notification, classes, isSelected } = this.props;

    if (isSelected) {
      return classes.selected;
    }
    return notification.attributes.markedReadAt ? classes.read : classes.unread;
  }

  render() {
    const { classes } = this.props;

    return (
      <div
        onClick={this.handleClick}
        className={classnames(classes.container, this.determineStatusClass())}
        data-cy="notifications-side-container"
      >
        {this.renderHeader()}
        {this.renderSubject()}
        {this.renderMessage()}
      </div>
    );
  }
}

const makeMapStateToProps = () => {
  const getNotificationItems = makeGetNotificationItems();

  const mapStateToProps = (state, ownProps) => ({
    notification: getNotificationItems(state)[ownProps.notificationId],
  });

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  withStyles(styles)(NotificationItem)
);
