import {
  Checkbox, TableCell, TableRow, TextField
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useFormikContext } from "formik";
import { debounce, get, omit } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import { GET } from "../../../../../util/apiHelpers";
import {
  ERROR, IDLE, LOADED, LOADING
} from "../../../../General/customHooks/usePagination";

import ConfirmationCellContent from "./ConfirmationCellContent";
import NoActionNeeded from "./NoActionNeeded";
import PreAssignmentDescriptionPopover from "./PreAssignmentDescriptionPopover";

function useWFMProfileNameFetch(wfmProfileId, contractId) {
  const [wfmProfileName, setWfmProfileName] = useState("");
  const [wfmProfileNameLoadStatus, setWfmProfileNameLoadStatus] = useState(IDLE);
  const [wfmProfileNameErrorMessage, setWfmProfileNameErrorMessage] = useState(null);

  const performFetch = useCallback(
    debounce((wfmProfileId, abortSignal) => {
      setWfmProfileNameLoadStatus(LOADING);
      GET(`/api/v3/employer/contracts/ukg_person_data?person_number=${wfmProfileId}&contract_id=${contractId}`, { signal: abortSignal })
        .then((response) => {
          setWfmProfileName(response.data.allExtension.employeeExtension.fullName);
          setWfmProfileNameLoadStatus(LOADED);
          setWfmProfileNameErrorMessage(null);
        })
        .catch((error) => {
          if (abortSignal.aborted) {
          } else {
            setWfmProfileName("");
            setWfmProfileNameErrorMessage(error.response.data.error);
            setWfmProfileNameLoadStatus(ERROR);
          }
        });
    }, 500),
    [setWfmProfileName, setWfmProfileNameLoadStatus, setWfmProfileNameErrorMessage]
  );

  useEffect(() => {
    if (wfmProfileId && wfmProfileId.length > 6) {
      const abortController = new AbortController(); // Create a new abort controller
      const abortSignal = abortController.signal;

      performFetch(wfmProfileId, abortSignal);

      return () => {
        abortController.abort();
      };
    }

    setWfmProfileName("");
    setWfmProfileNameLoadStatus(IDLE);
  }, [wfmProfileId, performFetch, setWfmProfileName, setWfmProfileNameLoadStatus]);

  return { wfmProfileName, wfmProfileNameLoadStatus, wfmProfileNameErrorMessage };
}

function RetrievedWFMProfileName({ wfmProfileName, wfmProfileNameLoadStatus }) {
  switch (wfmProfileNameLoadStatus) {
    case LOADING:
      return <LinearProgress />;
    case LOADED:
      return (
        <TextField
          label="Worker Profile"
          value={wfmProfileName}
          InputProps={{ disabled: true, style: { marginBottom: 0 } }}
          InputLabelProps={{ className: "disabled-input-label", shrink: true }}
        />
      );
    default:
      return null;
  }
}

function WFMProfileConfirmationField({ fieldId }) {
  const { values, handleChange } = useFormikContext();

  const value = get(values, fieldId);

  const isChecked = value.basicConfirmed;

  return (
    <div style={{ fontSize: 16 }}>
      Is this the correct worker? <Checkbox color="primary" id={`${fieldId}.basicConfirmed`} checked={isChecked} onChange={handleChange} />
    </div>
  );
}

function StaticCellContent({ fieldId }) {
  const { values } = useFormikContext();

  const value = get(values, fieldId);

  return (
    <div>
      <TextField
        label="WFM Profile Id"
        value={value.wfmProfileId}
        InputProps={{ disabled: true }}
        InputLabelProps={{ className: "disabled-input-label", shrink: true }}
      />
    </div>
  );
}

function EditableCellContent({ fieldId, candidate }) {
  const { values, setErrors, setFieldValue, status, setStatus } = useFormikContext();

  const value = get(values, fieldId);

  const {
    wfmProfileName,
    wfmProfileNameLoadStatus,
    wfmProfileNameErrorMessage
  } = useWFMProfileNameFetch(value.wfmProfileId, candidate.attributes.contractId);

  useEffect(() => {
    if (wfmProfileNameErrorMessage) {
      setErrors({ [`${fieldId}.wfmProfileId`]: wfmProfileNameErrorMessage });
    } else {
      setErrors({ [`${fieldId}.wfmProfileId`]: "" });
    }
  }, [wfmProfileNameErrorMessage, setErrors, fieldId]);

  const handleProfileIdChange = useCallback((e) => {
    const newWFMProfileId = e.target.value;
    setFieldValue(`${fieldId}.wfmProfileId`, newWFMProfileId);
    setFieldValue(`${fieldId}.basicConfirmed`, false);
  }, [setFieldValue, fieldId]);

  useEffect(() => {
    setStatus((status) => {
      return (omit(status, "submitErrors.wfm_profile_id"));
    });
  }, [value.wfmProfileId, setStatus]);

  const renderedErrorMessage = wfmProfileNameErrorMessage || status?.submitErrors?.wfm_profile_id;

  return (
    <div>
      <TextField
        label="WFM Profile Id"
        id={`${fieldId}.wfmProfileId`}
        onChange={handleProfileIdChange}
        error={Boolean(renderedErrorMessage)}
        helperText={renderedErrorMessage || ""}
        value={value.wfmProfileId}
      />
      <RetrievedWFMProfileName
        {...{ wfmProfileName, wfmProfileNameLoadStatus }}
      />
      {wfmProfileNameLoadStatus === LOADED && wfmProfileName && (
        <WFMProfileConfirmationField {...{ fieldId }} />
      )}
    </div>
  );
}

function WFMProfileIdRow({ checklistItem, editable, fieldId, candidate }) {
  return (
    <TableRow data-cy="checklist-item-confirmation-row">
      <TableCell>
        <div>
          <div className="bold">
            {checklistItem.name}
          </div>
          <div>
            (e.g. TMP13000001)
          </div>
        </div>
        <PreAssignmentDescriptionPopover {...checklistItem} />
      </TableCell>
      <TableCell align="center">
        <NoActionNeeded
          {...{
            checklistItem,
            editable,
            Editing: <EditableCellContent {...{ fieldId, candidate }} />,
            Viewing: <StaticCellContent {...{ fieldId }} />,
          }}
        />
      </TableCell>
      <TableCell>
        <ConfirmationCellContent {...{ checklistItem }} />
      </TableCell>
    </TableRow>
  );
}

export default WFMProfileIdRow;
